<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Редактировать домашнее задание
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы изменить настройки домашнего задания, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else>
            <div :class="{'ytm-inactive': wantToDeleteHomework}">
                <div class="ytm-fields">
                    <InputGroup class="ytm-modal-input" v-model="formData.topic" title="Тема" placeholder="Тема домашнего задания" :focus="true" @keyup.enter="editHomework"/>
                </div>
            </div>
            <Checkbox v-model="wantToDeleteHomework" id="remove" title="Удалить домашнее задание" @update:modelValue="errorMsg = ''"/>
            <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    v-if="!wantToDeleteHomework"
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="editHomework"
                >
                    Сохранить изменения
                </div>
                <div
                    v-else
                    class="ytm-blue-button ytm-default-text"
                    style="background-color: #F55A5A; font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="deleteHomework"
                >
                    Удалить
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import "@/components/redesign/Form/forms.css";
import Modal from "@/components/redesign/Modal.vue";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import Checkbox from "@/components/redesign/Form/Checkbox.vue";

export default {
    name: 'HomeworkSettingsModal',
    components: {Checkbox, InputGroup, DatetimeGroup, Modal},
    props: {
        homework: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formData: {
                topic: '',
            },
            wantToDeleteHomework: false,
            editing: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
    },
    methods: {
        editHomework() {
            if (this.editing || !this.homework) {
                return;
            }
            this.formData.topic = this.formData.topic.trim();
            if (!this.formData.topic) {
                this.errorMsg = 'Укажите, пожалуйста, тему домашнего задания';
                return;
            }
            this.errorMsg = '';
            this.editing = true;
            let payload = this.formData;
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MAIN_URI + '/homework',
                    payload,
                    {
                        params: {
                            courseId: this.$route.params.courseLink,
                            homeworkId: this.$route.params.homeworkId,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    this.$emit('homework_updated');
                    this.editing = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
                        this.errorMsg = err.response.data.message.slice('tariffError: '.length);
                    } else {
                        this.errorMsg = 'Упс, не удалось сохранить настройки домашнего задания';
                        console.error(err);
                    }
                    this.editing = false;
                });
            });
        },
        deleteHomework() {
            if (this.editing || !this.homework) {
                return;
            }
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.delete(
                    SERVICE_MAIN_URI + '/homework',
                    {
                        headers: authHeader(),
                        params: {
                            courseId: this.$route.params.courseLink,
                            homeworkId: this.$route.params.homeworkId,
                        },
                    },
                ).then(resp => {
                    this.$emit('homework_deleted');
                    this.editing = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    console.error(err);
                    this.errorMsg = 'Упс, не удалось удалить домашнее задание';
                    this.editing = false;
                });
            })
        },
    },
    mounted() {
        if (this.homework) {
            this.formData.topic = this.homework.topic;
        }
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>