<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Изменить статус домашнего задания
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы изменить статус домашнего задания, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else>
            <p v-html="warningMsg" class="ytm-default-text" style="text-align: center; font-size: 1.25rem; margin-bottom: 1.5rem"/>
            <p v-if="errorMsg" class="ytm-default-text" style="margin-bottom: -1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="changeStatus"
                >
                    Изменить статус
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup";
import homeworkStatuses from "@/util/homework-statuses";

export default {
    name: 'HomeworkStatusModal',
    components: {Modal, InputGroup, DatetimeGroup},
    props: {
        homework: {
            type: Object,
            required: true,
        },
        newStatus: {
            type: Number,
            required: true,
        },
        warningMsg: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            editing: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
    },
    methods: {
        changeStatus() {
            if (this.editing || !this.homework) {
                return;
            }
            this.errorMsg = '';
            this.editing = true;
            const payload = {newStatus: this.newStatus};
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MAIN_URI + '/homework',
                    payload,
                    {
                        params: {
                            courseId: this.$route.params.courseLink,
                            homeworkId: this.$route.params.homeworkId,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    this.editing = false;
                    this.$emit('status_changed');
                    this.$emit('reset_modal');
                }).catch(err => {
                    if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
                        this.errorMsg = err.response.data.message.slice('tariffError: '.length);
                    } else {
                        this.errorMsg = 'Упс, не удалось изменить статус';
                        console.error(err);
                    }
                    this.editing = false;
                });
            });
        },
    },
};
</script>

<style scoped>

</style>