<template>
    <div>
        <div v-if="isSocketReady && !!homework">
            <Materials
                :roomId="homework.materialsRoomId"
                :isRoomAdmin="roleId === roleIds.TEACHER"
                :active="homework.status === homeworkStatuses.ACTIVE"
                :useDrafts="homework.status < homeworkStatuses.DONE && roleId === roleIds.TEACHER"
                :showDraftsCircleButton="homework.status <= homeworkStatuses.ACTIVE && roleId === roleIds.TEACHER"
                :canPostNewMaterials="homework.status <= homeworkStatuses.ACTIVE && roleId === roleIds.TEACHER"
                :messageIfNoMaterials="messageIfNoMaterials"
                :typesConfig="typesConfig"
                style="margin-bottom: 50vh"
            />
        </div>
        <MaterialsSkeletons v-else/>
    </div>
</template>

<script>
import Materials from "@/components/MaterialsV2/Materials.vue";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";
import MaterialsSkeletons from "@/components/MaterialsV2/MaterialsSkeletons.vue";
import homeworkStatuses from "@/util/homework-statuses";
import roleIds from "@/util/roles";
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
    name: 'MaterialsWrapperOnHomework',
    components: {MaterialsSkeletons, DefaultTip, Materials},
    props: {
        homework: {
            type: [Object, null],
            required: true,
        },
        roleId: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const isSocketReady = computed(() => store.getters['ws/socketReady']);
        const messageIfNoMaterials = computed(() => {
            if (!props.homework) {
                return '';
            }
            switch (props.homework.status) {
                case homeworkStatuses.HIDDEN:
                    return 'Домашнее&nbspзадание скрыто от&nbspучеников –&nbspвы можете подготовить&nbspего и&nbspопубликовать 🚀';
                case homeworkStatuses.ACTIVE:
                    return 'Домашнее&nbspзадание активно, но&nbspпока&nbspне&nbspприкреплено ни&nbspодного&nbspматериала';
                case homeworkStatuses.DONE:
                    return 'Домашнее&nbspзадание завершено, материалы не&nbspбыли&nbspприкреплены';
                default:
                    return '';
            }
        });

        const typesConfig = ref({
            ctext: {
                name: 'Текстовый материал',
                isEditable: computed(() => props.homework.status <= homeworkStatuses.ACTIVE && props.roleId === roleIds.TEACHER),
            },
            images: { name: 'Изображения' },
            test: { name: 'Тест' },
        });

        return {isSocketReady, messageIfNoMaterials, typesConfig};
    },
    data() {
        return { homeworkStatuses, roleIds };
    },
};
</script>

<style scoped>

</style>