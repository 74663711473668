<template>
    <div class="ytm-container">
        <HomeworkSettingsModal
            v-if="modals.showSettingsModal"
            :homework="homework"
            @reset_modal="modals.showSettingsModal = false"
            @homework_updated="fetchHomework"
            @homework_deleted="onHomeworkDeleted"
        />
        <HomeworkStatusModal
            v-if="modals.showHomeworkStatusModal"
            :homework="homework"
            :newStatus="Number(currentStatus)"
            :warningMsg="statusWarning"
            @reset_modal="modals.showHomeworkStatusModal = false; currentStatus = String(homework.status)"
            @status_changed="fetchHomework"
        />
        <Navbar/>
        <div v-if="loading" class="ytm-lesson-head-wrapper" style="margin-top: 2.75rem; margin-bottom: 2.25rem">
            <div class="skeleton skeleton-text-big"/>
        </div>
        <div v-else>
            <div class="ytm-lesson-head-wrapper" style="margin-top: 2.75rem; margin-bottom: 2.25rem">
                <svg viewBox="0 0 21 35" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="ytm-lesson-head-icon"
                     style="margin-left: -0.5rem; margin-right: 0.75rem; height: 1.5rem; top: 0.45rem"
                     @click="goBack"
                >
                    <path d="M17.5 3L3 17.5L17.5 32" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="ytm-default-text ytm-head-text">
                    {{homework.topic}}
                </p>
                <svg v-if="isAbleToEditHomework" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-lesson-head-icon" @click="modals.showSettingsModal = true" style="margin-left: 0.75rem">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9248 2.73744C21.1003 -0.912479 15.8997 -0.91248 15.0753 2.73744C14.5409 5.10296 11.8264 6.22736 9.77585 4.93252C6.61199 2.93462 2.93462 6.61199 4.93252 9.77585C6.22736 11.8264 5.10296 14.5409 2.73744 15.0753C-0.912479 15.8997 -0.91248 21.1003 2.73744 21.9248C5.10296 22.4591 6.22736 25.1737 4.93252 27.2242C2.93462 30.388 6.61199 34.0654 9.77585 32.0675C11.8264 30.7727 14.5409 31.8971 15.0753 34.2626C15.8997 37.9125 21.1003 37.9125 21.9248 34.2626C22.4591 31.8971 25.1737 30.7727 27.2242 32.0675C30.388 34.0654 34.0654 30.388 32.0675 27.2242C30.7727 25.1737 31.8971 22.4591 34.2626 21.9248C37.9125 21.1003 37.9125 15.8997 34.2626 15.0753C31.8971 14.5409 30.7727 11.8264 32.0675 9.77585C34.0654 6.61199 30.388 2.93462 27.2242 4.93252C25.1737 6.22736 22.4591 5.10296 21.9248 2.73744ZM18.5 26C22.6421 26 26 22.6421 26 18.5C26 14.3579 22.6421 11 18.5 11C14.3579 11 11 14.3579 11 18.5C11 22.6421 14.3579 26 18.5 26Z" fill="black"/>
                </svg>
            </div>
            <div v-if="isAbleToEditHomework" class="ytm-hw-status" style="margin-top: -0.75rem; margin-bottom: 2.25rem">
                <SelectGroup
                    title="Статус домашнего задания"
                    :options="statusOptions"
                    v-model="currentStatus"
                    @update:modelValue="onStatusUpdate"
                />
            </div>
        </div>
        <MaterialsWrapperOnHomework :homework="homework" :roleId="roleId"/>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import "@/components/LessonPage/style.css";
import Navbar from "@/components/redesign/Navbar.vue";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import roleIds from "@/util/roles";
import {useRoute, useRouter} from "vue-router";
import MaterialsWrapperOnHomework from "@/components/HomeworkPage/MaterialsWrapperOnHomework.vue";
import HomeworkSettingsModal from "@/components/HomeworkPage/modals/HomeworkSettingsModal.vue";
import SelectGroup from "@/components/redesign/Form/SelectGroup.vue";
import HomeworkStatusModal from "@/components/HomeworkPage/modals/HomeworkStatusModal.vue";
import homeworkStatuses from "@/util/homework-statuses";

export default {
    name: 'HomeworkPage',
    components: {HomeworkStatusModal, SelectGroup, HomeworkSettingsModal, MaterialsWrapperOnHomework, Navbar},
    setup() {
        const store = useStore();
        const route = useRoute();

        const homework = ref(null);
        const loading = ref(true);
        const roleId = ref(-1);

        const statusOptions = ref([
            {value: '0', desc: 'Скрыто от учеников'},
            {value: '1', desc: 'Активно'},
            {value: '2', desc: 'Завершено'},
        ]);
        const currentStatus = ref(null);
        const statusWarning = ref('');

        const fetchHomework = () => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.get(
                    SERVICE_MAIN_URI + '/homework',
                    {
                        params: {
                            courseId: route.params.courseLink,
                            homeworkId: route.params.homeworkId,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    homework.value = resp.data.out.homework;
                    roleId.value = resp.data.out.roleId;
                    currentStatus.value = String(resp.data.out.homework.status);
                    loading.value = false;
                }).catch(err => {
                    console.log(err);
                    loading.value = false;
                });
            });
        };

        onMounted(() => {
            store.dispatch('ws/initialize').catch(err => {
                console.error(err);
            });
            store.dispatch('user_info/fetchUserInfo').catch(err => {
                console.error(err);
            });
            fetchHomework();
        });

        onBeforeUnmount(() => {
            store.dispatch('ws/closeConnection');
        });

        const isAbleToEditHomework = computed(() => {
            return homework && roleId.value === roleIds.TEACHER;
        });

        const modals = ref({
            showSettingsModal: false,
            showHomeworkStatusModal: false,
        });

        const onStatusUpdate = (value) => {
            if (Number(value) !== homework.value.status) {
                switch (Number(value)) {
                    case homeworkStatuses.HIDDEN:
                        statusWarning.value = 'Домашнее задание перейдёт в статус <b>Скрыто от учеников</b> – вы сможете редактировать домашнее задание, но ученики не будут его видеть';
                        break;
                    case homeworkStatuses.ACTIVE:
                        statusWarning.value = 'Домашнее задание перейдёт в статус <b>Активно</b> – оно появится у учеников в списке домашних заданий, и его можно будет выполнять';
                        break;
                    case homeworkStatuses.DONE:
                        statusWarning.value = 'Домашнее задание перейдёт в статус <b>Завершено</b> – оно будет доступно вам и ученикам, но изменять его будет нельзя';
                        break;
                    default:
                        statusWarning.value = '';
                }
                modals.value.showHomeworkStatusModal = true;
            }
        };

        const router = useRouter();
        const onHomeworkDeleted = () => {
            router.push('/course/' + route.params.courseLink + '?section=homeworks');
        };

        return {
            loading, homework, roleId, currentStatus,
            isAbleToEditHomework,
            fetchHomework, onStatusUpdate, onHomeworkDeleted,
            statusOptions, statusWarning, modals,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => { vm.prevRoute = from });
    },
    methods: {
        goBack() {
            const pathBack = this.prevRoute.href || '/course/' + this.$route.params.courseLink + '?section=homeworks';
            this.$router.push(pathBack);
        },
    },
};
</script>

<style scoped>
.ytm-hw-status {
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    padding: 0.5rem 0 0.5rem 16px;
    transition: 0.1s all ease-in-out;
}

.ytm-hw-status:hover {
    border-color: #68cef8;
}
</style>